.slide {
    display: flex;
    justify-content: center;

    margin-top: 96px;

    .content {
        display: block;
        max-width: 1360px;
        align-items: center;
        text-align: center;

        img {
            transition: 300ms;
            padding: 5px;
            width: 96px;

            @media only screen and (min-width: 720px) {
                &:hover {
                    transform: rotate(15deg);
                    transition: 300ms;
                }
            }
        }
    }
}