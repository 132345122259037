.header {
    @media only screen and (max-width: 720px) {
        margin-top: 0px;
    }

    text-align: center;
    margin-top: 132px;

    display: block;

    .title {
        @media only screen and (max-width: 720px) {
            font-size: 76px;
        }

        color: white;
        font-size: 96px;
        font-weight: 800;

        .name {
            background: linear-gradient(350deg, #fded04, #f66e48, #ea00ff);
            background-size: 200%;
            background-clip: text;

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
        }
    }

    .subtitle {
        @media only screen and (max-width: 720px) {
            font-size: 24px;
        }

        color: #A8A8A8;
        font-size: 30px;
        font-weight: 300;
    }
}