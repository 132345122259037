.navigation {
    @media only screen and (max-width: 720px) {
        display: inline;
        visibility: hidden;
    }

    margin-top: 32px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    
    a {
        margin-left: 30px;
        margin-right: 30px;
        
        text-decoration: none;
        color: white;
        font-size: 20px;
        color: #A8A8A8;

        &:hover {
            color: white;
            transition: 300ms;
        }
    }
}