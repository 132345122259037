.footer {
    @media only screen and (max-width: 720px) {
        flex-direction: column;
        margin-top: 32px;
    }

    margin-top: 72px;
    margin-bottom: 72px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    button {
        @media only screen and (max-width: 720px) {
            margin-top: 32px;
        }

        background-color: white;
        border-radius: 8px;
        border: 4px solid white;
        padding: 16px 84px 16px 84px;
        margin: 0 16px 0 16px;

        font-size: 24px;
        font-weight: 500;

        &:hover {
            color: white;
            background-color: black;
            border: 4px solid white;
            cursor: pointer;

            transition: 300ms;
        }
    }
}