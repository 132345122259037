.about {
    margin-top: 132px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 1400px;

    .title {
        @media only screen and (max-width: 720px) {
            font-size: 60px;
        }

        color: white;
        font-size: 72px;
        font-weight: 800;

        .name {
            background: linear-gradient(350deg, #fded04, #f66e48, #ea00ff);
            background-size: 200%;
            background-clip: text;

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
        }
    }

    .subtitle {
        @media only screen and (max-width: 720px) {
            font-size: 20px;
            padding-left: 25px;
            padding-right: 25px;
        }

        margin-top: 8px;
        color: #A8A8A8;
        font-size: 24px;
        font-weight: 300;
        line-height: 32px;
    }
}